import React from 'react'
import './lotus-prompt.css'
import BaseLeaf from './leaves/lotus-base.svg'
import TopLeftLeaf from './leaves/lotus-left-up.svg'
import BottomLeftLeaf from './leaves/lotus-left-down.svg'
import BottomRightLeaf from './leaves/lotus-right-down.svg'
import TopRightLeaf from './leaves/lotus-right-up.svg'
// Import other leaf SVGs as needed

const LotusPrompt = (params: {
  base: boolean
  topLeft: boolean
  bottomRight: boolean
  topRight: boolean
  bottomLeft: boolean
  containerClass?: string
}) => {
  const { base, topLeft, bottomRight, topRight, bottomLeft, containerClass } =
    params
  return (
    <div className={containerClass ?? 'lotus-container'}>
      <object
        aria-label="lotus"
        type="image/svg+xml"
        data={BaseLeaf}
        className={`leaf ${base ? 'highlighted' : ''}`}
      ></object>
      <object
        aria-label="lotus"
        type="image/svg+xml"
        data={TopLeftLeaf}
        className={`leaf ${topLeft ? 'highlighted' : ''}`}
      ></object>
      <object
        aria-label="lotus"
        type="image/svg+xml"
        data={BottomLeftLeaf}
        className={`leaf ${bottomLeft ? 'highlighted' : ''}`}
      ></object>
      <object
        aria-label="lotus"
        type="image/svg+xml"
        data={TopRightLeaf}
        className={`leaf ${topRight ? 'highlighted' : ''}`}
      ></object>
      <object
        aria-label="lotus"
        type="image/svg+xml"
        data={BottomRightLeaf}
        className={`leaf ${bottomRight ? 'highlighted' : ''}`}
      ></object>
    </div>
  )
}

export default LotusPrompt
