import React, { useCallback, useEffect, useMemo } from 'react'
import { ServiceFactory } from '../../service/factory'
import styles from './flows.module.css'
import useYogaStore from '../../hooks/use-yoga-store'
import {
  Card,
  CardActions,
  CardContent,
  CardCover,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/joy'
import { PlayArrow } from '@mui/icons-material'
import LoginButton from './auth/login-button'
import { Flow, FlowingPose } from '../../service/types'
import { useNavigate } from 'react-router-dom'
import { CardHeader } from '@mui/material'
import LotusPrompt from './lotus/lotus-prompt'
import ShowMore from './show-more'

const PublicFlows = () => {
  const currentUser = useYogaStore((state) => state.currentUser)
  const flows = useYogaStore((state) => state.flowService.flows)
  const setFlows = useYogaStore((state) => state.flowService.setFlows)

  useEffect(() => {
    ServiceFactory.flow.listPublic(setFlows)
  }, [setFlows])

  const navigate = useNavigate()
  const breathLengthMS = 4500

  const calcFlowStats = (flow: Flow): { length: number; level: number } => {
    const transitionTimeMs = flow.transitions.reduce((acc, transition) => {
      if (transition.to?.type === 'Pose') {
        const transitionAudioDuration = transition.audio?.duration ?? 0
        const nextPoseBreathsDuration =
          transition.to.holdFor > 0
            ? breathLengthMS * transition.to.holdFor
            : breathLengthMS / 2
        const stayHereFor =
          transitionAudioDuration > nextPoseBreathsDuration
            ? transitionAudioDuration + 3000
            : nextPoseBreathsDuration

        acc += stayHereFor
      }
      return acc
    }, 0)

    const firstPose = flow.transitions[0].from as FlowingPose
    const firstPoseBreathsDuration =
      firstPose.holdFor > 0
        ? breathLengthMS * firstPose.holdFor
        : breathLengthMS / 2
    const firstPoseAudioDuration = firstPose.audioClip?.duration ?? 0
    const intentionTimeMs =
      firstPoseAudioDuration > firstPoseBreathsDuration
        ? firstPoseAudioDuration + 3000
        : firstPoseBreathsDuration

    return { length: intentionTimeMs + transitionTimeMs, level: 100 }
  }

  return (
    <Grid container spacing={2} sx={{ flexGrow: 1, padding: '1rem' }}>
      <Grid xs={12} textAlign="center">
        <Card
          sx={{
            flexGrow: 1,
            cursor: 'pointer',
            textAlign: 'justify',
            opacity: 0.8,
          }}
          variant="outlined"
          // className={`inset glowing ${styles.card} `}
        >
          <CardCover
            className={styles.cardCover}
            sx={{
              backdropFilter: 'blur(10px) saturate(150%)',
              opacity: 0.15,
              filter: 'blur(10px)  saturate(150%)',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              backgroundImage: `url('/card-cover-3.webp')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          ></CardCover>
          <CardContent>
            <h1 style={{ textAlign: 'center' }}>Flow, with Intention</h1>
            <h2 style={{ textAlign: 'center' }}>
              Hello, {currentUser?.name ?? 'Friend'}
            </h2>
            <CardContent>
              <p style={{ textAlign: 'center' }}>
                Ground your purpose, connect your body, and manifest your goals
                with our guided yoga flows.
              </p>
            </CardContent>
          </CardContent>
        </Card>
      </Grid>

      <LotusPrompt
        containerClass="landing-page-lotus-container"
        base={true}
        bottomLeft={true}
        bottomRight={true}
        topLeft={true}
        topRight={true}
      />
      {/* <Grid xs={12} textAlign="center">
        <div className={styles.logoContainer}>
          <div className={`${styles.pulseShadow} ${styles.logoChild}`}></div>
          <div className={`${styles.pulse} ${styles.logoChild}`}></div>
          <div className={`${styles.logo} ${styles.logoChild}`}></div>
        </div>
      </Grid> */}
      {/* Introduction and CTA */}
      {/* CTA for Guest Users */}
      <Grid xs={12} textAlign="center">
        <Card>
          <CardCover
            sx={{
              backdropFilter: 'blur(10px) saturate(50%)',
              opacity: 0.25,
              filter: 'blur(5px)  saturate(150%)',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              backgroundImage: `url('/card-cover.webp')`,
              backgroundSize: 'cover',
            }}
          ></CardCover>
          {!currentUser && (
            <>
              <h4>
                Log in to unlock progress tracking and personalized insights
                into your yoga journey. Stay connected with your goals and
                celebrate your growth—all with a free account!
              </h4>
              <LoginButton />
            </>
          )}
          {<h4>- Explore one of our free flows -</h4>}
          <List>
            {Object.values(flows)
              .sort((a: Flow, b: Flow) => {
                if (!a.updatedAt || !b.updatedAt) return 0
                return new Date(b.updatedAt) < new Date(a.updatedAt) ? 1 : -1
              })
              .map((flow) => {
                const flowStats = calcFlowStats(flow)
                return (
                  <ListItem key={flow.id}>
                    <Card
                      sx={{
                        flexGrow: 1,
                        margin: '1rem',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        navigate(`/flow/${flow.id}`)
                      }}
                      variant="outlined"
                      className={`inset ${styles.card} `}
                    >
                      <CardCover
                        sx={{
                          backdropFilter: 'blur(10px) saturate(50%)',
                          opacity: 0.25,
                          filter: 'blur(5px)  saturate(150%)',
                          backgroundColor: 'rgba(255, 255, 255, 0.5)',
                          backgroundImage: `url('/card-cover.webp')`,
                          backgroundSize: 'cover',
                        }}
                      ></CardCover>

                      <CardContent>
                        <h4>{flow.name}</h4>
                      </CardContent>
                      <CardContent>
                        <ShowMore text={flow?.synopsis} maxLength={180} />
                      </CardContent>
                      <CardContent>
                        about {Math.ceil(flowStats.length / (1000 * 60))}{' '}
                        minutes
                      </CardContent>
                      <CardActions orientation="vertical">
                        <IconButton
                          onClick={() => {
                            navigate(`/flow/${flow.id}`)
                          }}
                        >
                          <PlayArrow />
                        </IconButton>
                      </CardActions>
                    </Card>
                  </ListItem>
                )
              })}
          </List>
        </Card>
      </Grid>

      <Card
        sx={{
          flexGrow: 1,
          margin: '1rem',
          cursor: 'pointer',
          textAlign: 'justify',
          opacity: 0.8,
        }}
        variant="outlined"
        className={`outset glowing ${styles.card} `}
      >
        <CardCover
          sx={{
            backdropFilter: 'blur(10px) saturate(50%)',
            opacity: 0.25,
            filter: 'blur(10px)  saturate(150%)',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            backgroundImage: `url('/card-cover-3.webp')`,
            backgroundSize: 'cover',
          }}
        ></CardCover>
        <CardContent>
          <h2 style={{ textAlign: 'center' }}>About Us</h2>
          <p>
            At The Intention Engine, we believe in the power of intention as the
            foundation of every experience we create. Each flow begins with a
            purposeful focus, set with care, that guides the process from start
            to finish. Our intention setting is more than just a starting point;
            it\'s the core of a guided manifestation that shapes every step of
            the journey. With each breath, movement, and moment, our flows are
            crafted to support and amplify your unique goals, helping you to
            bring your intentions to life. Our approach is to bring you closer
            to your truest self, with the flow as your guide toward positive
            transformation.
          </p>
        </CardContent>
      </Card>

      <Card
        sx={{
          flexGrow: 1,
          margin: '1rem',
          cursor: 'pointer',
          textAlign: 'justify',
          opacity: 0.8,
        }}
        variant="outlined"
        className={`outset glowing ${styles.card} `}
      >
        <CardCover
          sx={{
            backdropFilter: 'blur(10px) saturate(50%)',
            opacity: 0.25,
            filter: 'blur(10px)  saturate(150%)',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            backgroundImage: `url('/card-cover-3.webp')`,
            backgroundSize: 'cover',
          }}
        ></CardCover>
        <CardContent>
          <h2 style={{ textAlign: 'center' }}>Coming Soon</h2>
          <p>
            Imagine setting your own intention and experiencing a flow generated
            specifically to manifest it for you. Soon, you\'ll be able to
            personalize your practice by choosing a focus that aligns with your
            unique goals and dreams, creating an experience designed to support
            and empower your journey. Set your intention, and let the flow guide
            you.
          </p>

          <p>
            Interested in knowing more? Contact us via{' '}
            <a href="mailto:hi@ntntn.io">email</a> or{' '}
            <a href="https://discord.gg/gRnWXSSpE2">Join us on Discord</a>.
          </p>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default PublicFlows
