const FriendlyTime = (params: { seconds: number }) => {
  const { seconds } = params
  // const [time, setTime] = useState(seconds)

  const formatTime = () => {
    const date = new Date(0)
    date.setSeconds(seconds) // specify value for SECONDS here
    return date.toISOString().substring(11, 19)
  }

  return formatTime()
}

export default FriendlyTime
