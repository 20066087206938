import { useCallback, useEffect, useRef, useState } from 'react'
import useYogaStore from '../../hooks/use-yoga-store'
import FlowPlayerService, { FlowPlayerEvent } from '../../service/flow-player'
import {
  ButtonGroup,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Modal,
  ModalClose,
  ModalDialog,
  Select,
  Option,
  Sheet,
  Typography,
  Box,
  Slider,
  Drawer,
  Textarea,
  DialogActions,
  Button,
  Divider,
  LinearProgress,
} from '@mui/joy'
import ReactPlayer from 'react-player'
import {
  ArrowDownward,
  ArrowUpwardTwoTone,
  PauseCircleOutlineTwoTone,
  PlayCircle,
  PlayCircleFilledOutlined,
  Refresh,
  SkipNext,
} from '@mui/icons-material'
import { ServiceFactory } from '../../service/factory'
import {
  AudioClip,
  AuthedUser,
  Flow,
  FlowingPose,
  FlowingTransition,
  FlowUserAnalytics,
} from '../../service/types'
import LotusPrompt from './lotus/lotus-prompt'
import { useLoaderData } from 'react-router-dom'
import './flow-player.css'
import { Helmet } from 'react-helmet-async'
import Rating from './rating'
import { ScreenOrientation } from '@capacitor/screen-orientation'
import { Capacitor } from '@capacitor/core'
import { on } from 'events'
import FriendlyTime from './friendly-time'

const FlowLoader = ({
  params,
}: {
  params: { flowId?: string }
}): { flowId: string } => {
  if (!params.flowId) {
    throw new Error('Flow ID is required')
  }
  return { flowId: params.flowId }
}

const FlowPlayer = () => {
  const currentUser = useYogaStore((state) => state.currentUser)

  const { flowId } = useLoaderData() as { flowId: string }

  const flows = useYogaStore((state) => state.flowService.flows)
  const [flow, setFlow] = useState<Flow | undefined>()
  const [stats, setStats] = useState<FlowUserAnalytics | undefined>()

  const [player, setPlayer] = useState<HTMLDivElement>()
  const [playing, setPlaying] = useState<boolean>(false)
  const [paused, setPaused] = useState<boolean>(false)
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [currentPoseVideoUrl, setCurrentPoseVideoUrl] = useState<
    string | undefined
  >()
  const [currentAudio, setCurrentAudio] = useState<AudioClip | undefined>()
  const [currentPose, setCurrentPose] = useState<FlowingPose>()
  const [nextPose, setNextPose] = useState<FlowingPose>()
  const [timeLeft, setTimeLeft] = useState<number>()
  const [audio, setAudio] = useState<HTMLAudioElement>()
  const [musicPlayer, setMusicPlayer] = useState<ReactPlayer>()
  const [flowCompletedOpen, setFlowCompletedOpen] = useState<boolean>(false)

  const flowPlayer = useRef<FlowPlayerService | undefined>(undefined)
  const [musicVolume, setMusicVolume] = useState(0.5)
  const [promptVolume, setPromptVolume] = useState(0.5)

  const [userRating, setUserRating] = useState<number>(0)
  const [feedback, setFeedback] = useState<string>('')

  const [progress, setProgress] = useState<number>(0)
  const [duration, setDuration] = useState<number>(0)

  ServiceFactory.analytics.instance.page({
    url: window.location.href,
    title: document.title,
  })

  const audioChoices = {
    'https://www.youtube.com/watch?v=rR2jsNdtlSA':
      'A Message from the Stars 🌌',
    'https://www.youtube.com/watch?v=1LHs8BfH6yU': 'Ambient Introvert 🎶',
    'https://www.youtube.com/watch?v=zBGVbVmxU_U': 'Dystopian Soundscapes 🎵',
    'https://www.youtube.com/watch?v=mx-5WNASaf0': 'Space Ambient Music 🚀',
    'https://www.youtube.com/watch?v=pSaB6Ku6UVI': 'Deep Meditation 🧠✨',
    'https://www.youtube.com/watch?v=azfQScEdDa0': 'Power Flow sounds 🧘‍♂️',
    'https://www.youtube.com/watch?v=uY_-JQMtW6Y': 'Upbeat Yoga Sounds 🎶',
    'https://www.youtube.com/watch?v=L3kL7zwNYP8':
      'Alone | Beautiful Chill Music Mix 🎧',
  }

  const [selectedAudio, setSelectedAudio] = useState<string>(
    'https://www.youtube.com/watch?v=L3kL7zwNYP8',
  )

  const doSetPromptVolume = (volume: number) => {
    if (audio) {
      audio.volume = volume
      setPromptVolume(volume)
    }
  }

  const setFlowCompleted = useCallback(() => {
    if (currentUser && stats?.id) {
      ServiceFactory.stats
        .completeFlow(currentUser, flowId, stats.id, 0)
        .then((stats) => {
          setStats(stats)
        })
    }
  }, [currentUser, stats, flowId])

  const fullScreen = useCallback(() => {
    if (player?.requestFullscreen) {
      player.requestFullscreen()
    }
  }, [player])

  useEffect(() => {
    const fetchFlow = async () => {
      const playableFlow = await ServiceFactory.flow.play(flowId, currentUser)
      console.log('playableFlow', playableFlow)
      setFlow(playableFlow)
    }

    if (flowId && !flow) {
      fetchFlow()
    }
  }, [flowId, currentUser, flows, flow])

  useEffect(() => {
    const lockOrientation = async () => {
      await ScreenOrientation.lock({ orientation: 'landscape' })
    }

    if (
      Capacitor.getPlatform() === 'android' &&
      Capacitor.isPluginAvailable('ScreenOrientation')
    ) {
      lockOrientation()
    }
  }, [])

  useEffect(() => {
    if (currentAudio) {
      if (audio) {
        if (!audio.paused) {
          audio.pause()
        }
        audio.src = `https://storage.googleapis.com/stoner-yoga/${currentAudio.url}`
        audio.play()
      }
    }
  }, [currentAudio, audio])

  useEffect(() => {
    document.title = flow?.name ?? 'Yoga, with ntntn'
    if (flow?.name) {
      ServiceFactory.analytics.instance.page(
        {
          url: window.location.href,
          title: document.title,
        },
        {
          flow: flow.name,
        },
        (params: any) => {
          console.log('page', params)
        },
      )
    }
  }, [flow?.name])

  const moveToNextPose = () => {
    setPlaying(true)
    flowPlayer.current?.nextTransition()
  }

  const start = () => {
    setPlaying(true)
    flowPlayer.current?.start()
    if (currentUser && !stats) {
      ServiceFactory.stats.startFlow(currentUser, flowId).then((stats) => {
        setStats(stats)
      })
    }
  }

  const pause = () => {
    setPlaying(false)
    flowPlayer.current?.pause()
    audio?.pause()
  }

  const resume = () => {
    flowPlayer.current?.resume()
    audio?.play()
  }

  const restart = () => {
    flowPlayer.current?.restart()
  }

  const setPlayerRef = (elem: HTMLDivElement | null) => {
    if (elem) {
      setPlayer(elem)
    }
  }

  const setAudioRef = (elem: HTMLAudioElement | null) => {
    if (elem) {
      setAudio(elem)
      elem.volume = promptVolume
    }
  }

  const setMusicPlayerRef = (elem: ReactPlayer | null) => {
    if (elem) {
      setMusicPlayer(elem)
    }
  }

  const onPlay = useCallback((evt: CustomEventInit) => {
    console.log(`flow player playing: ${evt.detail}`)
    setPlaying(evt.detail)
    setPaused(false)
  }, [])

  const onPause = useCallback(() => {
    console.log(`flow player paused: `)
    setPaused(true)
  }, [])

  const onCountDown = useCallback((evt: CustomEventInit) => {
    setTimeLeft(evt.detail)
  }, [])

  const onCompleted = useCallback(() => {
    setFlowCompletedOpen(true)
    setTimeLeft(undefined)
    setPlaying(false)
    setCurrentPoseVideoUrl(undefined)
    setCurrentPose(undefined)
    setNextPose(undefined)
    setFlowCompleted()
  }, [])

  const onTransitionStart = useCallback((evt: CustomEventInit) => {
    console.log(`TRANSITION_START`, evt.detail)
    setCurrentAudio(evt.detail.transition.audio)
    // console.log('setting current pose', params.transition.to?.name)
    setCurrentPose(evt.detail.transition.to as FlowingPose)
    // console.log('setting next pose', params.nextTransition.to?.name)
    setNextPose(evt.detail.nextTransition.to as FlowingPose)
  }, [])

  const onIntentionStart = useCallback((evt: CustomEventInit) => {
    setCurrentAudio(evt.detail.pose.audioClip)
    // console.log('setting current pose', params.pose.name)
    setCurrentPose(evt.detail.pose)
    // console.log('setting next pose', params.nextTransition.to?.name)
    setNextPose(evt.detail.nextTransition.to as FlowingPose)
  }, [])

  const onElapsedTime = useCallback((evt: CustomEventInit) => {
    const { elapsed, total } = evt.detail
    console.log(`ELAPSED_TIME ${elapsed} of ${total}`)
    setProgress((elapsed / total) * 100)
    setDuration(elapsed)
  }, [])

  useEffect(() => {
    const setupFlowPlayer = async (currentUser?: AuthedUser) => {
      if (!flow) return
      // const flow = await ServiceFactory.flow.play(currentFlowId, currentUser)

      // if (flowPlayer.current && flowPlayer.current.curre()) {
      //   flowPlayer.current.end()
      //   flowPlayer.current.removeAllListeners()
      // }
      flowPlayer.current = new FlowPlayerService({
        flow,
        breathLengthMS: 4750,
      })

      flowPlayer.current.addEventListener(FlowPlayerEvent.PLAYING, onPlay)

      flowPlayer.current.addEventListener(FlowPlayerEvent.PAUSED, onPause)

      flowPlayer.current.addEventListener(
        FlowPlayerEvent.COUNTDOWN,
        onCountDown,
      )

      flowPlayer.current.addEventListener(
        FlowPlayerEvent.COMPLETED,
        onCompleted,
      )

      flowPlayer.current.addEventListener(
        FlowPlayerEvent.TRANSITION_START,
        onTransitionStart,
      )

      flowPlayer.current.addEventListener(
        FlowPlayerEvent.INTENTION_START,
        onIntentionStart,
      )

      flowPlayer.current.addEventListener(
        FlowPlayerEvent.ELAPSED_TIME,
        onElapsedTime,
      )
    }

    setupFlowPlayer(currentUser)

    return () => {
      flowPlayer.current?.removeEventListener(FlowPlayerEvent.PLAYING, onPlay)
      flowPlayer.current?.removeEventListener(FlowPlayerEvent.PAUSED, onPause)
      flowPlayer.current?.removeEventListener(
        FlowPlayerEvent.COUNTDOWN,
        onCountDown,
      )
      flowPlayer.current?.removeEventListener(
        FlowPlayerEvent.COMPLETED,
        onCompleted,
      )
      flowPlayer.current?.removeEventListener(
        FlowPlayerEvent.TRANSITION_START,
        onTransitionStart,
      )
      flowPlayer.current?.removeEventListener(
        FlowPlayerEvent.INTENTION_START,
        onIntentionStart,
      )
      flowPlayer.current?.removeEventListener(
        FlowPlayerEvent.ELAPSED_TIME,
        onElapsedTime,
      )

      flowPlayer.current?.end()
      flowPlayer.current = undefined
      console.log('cleaned up')
    }
  }, [
    currentUser,
    flow,
    onCompleted,
    onCountDown,
    onElapsedTime,
    onPause,
    onPlay,
    onTransitionStart,
    onIntentionStart,
  ])

  const posePreview = () => {
    if (currentPose) {
      return (
        <Sheet
          sx={{
            margin: '0px auto',
            position: 'relative',
            top: '175px',
            backgroundColor: '#222',
          }}
        >
          <img alt="" src={`/poses/${currentPose.image}`}></img>
        </Sheet>
      )
    }
  }

  const displayLotusPrompt = () => {
    return (
      <LotusPrompt
        base={
          !currentPose
            ? true
            : currentPose?.orientation === 'center'
              ? true
              : false
        }
        bottomRight={
          !currentPose
            ? true
            : currentPose?.orientation === 'right'
              ? true
              : false
        }
        topRight={
          !currentPose
            ? true
            : currentPose?.orientation === 'right'
              ? true
              : false
        }
        bottomLeft={
          !currentPose
            ? true
            : currentPose?.orientation === 'left'
              ? true
              : false
        }
        topLeft={
          !currentPose
            ? true
            : currentPose?.orientation === 'left'
              ? true
              : false
        }
      />
    )
  }

  return (
    <>
      <Helmet>
        <title>{flow?.name}</title>
        {/* add all the social media meta tags */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={flow?.name} />
        <meta property="og:description" content={flow?.synopsis} />
        <meta property="og:image" content="https://yoga.ntntn.io/lotus2.svg" />
        <meta property="og:url" content="https://yoga.ntntn.io" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@ntntn" />
        <meta name="twitter:title" content={flow?.name} />
        <meta name="twitter:description" content={flow?.synopsis} />
        <meta name="twitter:image" content="https://yoga.ntntn.io/lotus2.svg" />
        <meta name="twitter:creator" content="@ntntn" />
        <meta name="description" content={flow?.name} />
      </Helmet>
      <Grid
        xs={12}
        container
        ref={(element) => setPlayerRef(element)}
        className="player flow"
        height={'100%'}
        // sx={{ height: '100vh' }}
      >
        <audio ref={(element) => setAudioRef(element)}></audio>
        {
          <ReactPlayer
            style={{
              display: 'none',
              margin: '0px auto',
              position: 'relative',
              pointerEvents: 'none',
            }}
            // muted
            ref={(element) => setMusicPlayerRef(element)}
            pip={false}
            volume={musicVolume}
            height={700}
            controls={false}
            config={{
              youtube: {
                playerVars: {
                  showinfo: 0,
                  rel: 0,
                  iv_load_policy: 3,
                  modestbranding: 1,
                },
              },
            }}
            playing={playing}
            url={selectedAudio}
          ></ReactPlayer>
        }
        <Grid
          container
          xs={12}
          sx={{
            margin: '1rem 2rem',
            width: '100%',
          }}
        >
          <Grid xs={12} textAlign={'center'}>
            <Typography level="h1">{flow?.name}</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          // direction="row"
          justifyContent="flex-end"
          alignItems="center"
          xs={12}
          md={6}
          textAlign="center"
        >
          {displayLotusPrompt()}
        </Grid>
        {!currentPose && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            xs={12}
            md={6}
            sx={{ verticalAlign: 'middle' }}
          >
            {/* {!currentPoseVideoUrl && posePreview()} */}
            <IconButton
              variant="plain"
              sx={{
                // scale: 8,
                border: 0,
                boxShadow: 'none !important',
                opacity: 0.5,
                minWidth: '30vh',
                minHeight: '30vh',
                svg: {
                  fill: '#000',
                  scale: 8,
                },
              }}
              size="lg"
              onClick={() => start()}
            >
              <PlayCircleFilledOutlined />
            </IconButton>
          </Grid>
        )}
        {currentPose && (
          <Grid
            direction="column"
            justifyContent="flex-end"
            alignItems="center"
            xs={12}
            md={6}
            sx={{ verticalAlign: 'middle', margin: 'auto' }}
          >
            <Typography level="h2" sx={{ textAlign: 'center', width: '100%' }}>
              {currentPose.name}
            </Typography>
            <Typography level="h2" sx={{ textAlign: 'center', width: '100%' }}>
              {nextPose && `${nextPose.name} in `}{' '}
              {timeLeft && Math.ceil(timeLeft / 1000).toFixed(0)}
            </Typography>
          </Grid>
        )}

        {currentPose && (
          <>
            <Grid xs={12} textAlign="center">
              <LinearProgress
                variant="solid"
                thickness={3}
                determinate
                value={progress}
              />
            </Grid>
            <Grid xs={6} textAlign="left">
              <Typography level="h4">
                <FriendlyTime seconds={duration / 1000} />
              </Typography>
            </Grid>
            <Grid xs={6} textAlign="right">
              <Typography level="h4">{`${progress.toFixed(0)}% complete`}</Typography>
            </Grid>
          </>
        )}

        {!menuOpen && (
          <Grid
            xs={12}
            textAlign="center"
            sx={{
              position: 'fixed', // Fixed to the viewport
              bottom: menuOpen ? '40%' : 0, // Adjust position when the drawer is open
              left: '50%', // Center horizontally
              transform: 'translateX(-50%)', // Center the button perfectly
              transition: 'bottom 0.3s ease-in-out', // Smooth transition for rising effect
            }}
          >
            <IconButton onClick={() => setMenuOpen(!menuOpen)}>
              <ArrowUpwardTwoTone />
            </IconButton>
            <ButtonGroup size="lg" sx={{ justifySelf: 'center' }}>
              {/* <IconButton onClick={() => fullScreen()}>
            <Fullscreen />
          </IconButton> */}
              <IconButton onClick={() => restart()}>
                <Refresh />
              </IconButton>
              {!playing && !paused && (
                <IconButton onClick={() => start()}>
                  <PlayCircle />
                </IconButton>
              )}
              {playing && !paused && (
                <IconButton onClick={() => pause()}>
                  <PlayCircleFilledOutlined />
                </IconButton>
              )}
              {paused && (
                <IconButton onClick={() => resume()}>
                  <PauseCircleOutlineTwoTone />
                </IconButton>
              )}
              <IconButton onClick={() => moveToNextPose()}>
                <SkipNext />
              </IconButton>
            </ButtonGroup>
          </Grid>
        )}

        <Drawer
          anchor={'bottom'}
          open={menuOpen}
          hideBackdrop
          // onClose={toggleDrawer(anchor, false)}
        >
          <IconButton onClick={() => setMenuOpen(false)}>
            <ArrowDownward />
          </IconButton>
          <Grid
            container
            sx={{
              position: 'relative',
              display: 'grid',
              bottom: '0px',
              width: '100%',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center', // Center the contents horizontally
              padding: '1rem', // Add padding for spacing
              // backgroundColor: 'rgba(255, 255, 255, 0.1)', // Optional background for better visibility
            }}
          >
            <ButtonGroup size="lg" sx={{ justifySelf: 'center' }}>
              {/* <IconButton onClick={() => fullScreen()}>
            <Fullscreen />
          </IconButton> */}
              <IconButton onClick={() => restart()}>
                <Refresh />
              </IconButton>
              {!playing && !paused && (
                <IconButton onClick={() => start()}>
                  <PlayCircle />
                </IconButton>
              )}
              {playing && !paused && (
                <IconButton onClick={() => pause()}>
                  <PlayCircleFilledOutlined />
                </IconButton>
              )}
              {paused && (
                <IconButton onClick={() => resume()}>
                  <PauseCircleOutlineTwoTone />
                </IconButton>
              )}
              <IconButton onClick={() => moveToNextPose()}>
                <SkipNext />
              </IconButton>
            </ButtonGroup>{' '}
            <Grid xs={12}>
              <Select
                sx={{ maxWidth: '300px', margin: '0 auto' }}
                value={selectedAudio}
                onChange={(e, value) => setSelectedAudio(value as string)}
              >
                {Object.entries(audioChoices).map(([url, label]) => (
                  <Option key={url} value={url}>
                    {label}
                  </Option>
                ))}
              </Select>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box width={275} sx={{ alignSelf: 'center' }}>
                <Slider
                  size="sm"
                  marks={[
                    {
                      value: 0,
                      label: '',
                    },
                    {
                      value: 100,
                      label: '',
                    },
                  ]}
                  step={11}
                  value={Math.abs(promptVolume ?? 0) * 100}
                  // valueLabelDisplay="auto"
                  min={0}
                  max={100}
                  onChange={(_: Event, newValue: number | number[]) => {
                    console.log('newValue', newValue)
                    // setVolumeBalance(newValue as number)
                    // set the voice
                    doSetPromptVolume(Math.abs(newValue as number) / 100)
                  }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    level="body-sm"
                    onClick={() => setPromptVolume(0)}
                    sx={{ cursor: 'pointer' }}
                  >
                    mute voice
                  </Typography>
                  <Typography
                    level="body-sm"
                    onClick={() => setPromptVolume(1)}
                    sx={{ cursor: 'pointer' }}
                  >
                    voice {Math.ceil((audio?.volume ?? 0) * 100)}%
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box width={275} sx={{}}>
                <Slider
                  size="sm"
                  marks={[
                    {
                      value: 0,
                      label: '',
                    },
                    {
                      value: 100,
                      label: '',
                    },
                  ]}
                  step={11}
                  value={Math.abs(musicVolume ?? 0) * 100}
                  // valueLabelDisplay="auto"
                  min={0}
                  max={100}
                  onChange={(_: Event, newValue: number | number[]) => {
                    console.log('newValue', newValue)
                    setMusicVolume(Math.abs(newValue as number) / 100)
                  }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    level="body-sm"
                    onClick={() => setMusicVolume(0)}
                    sx={{ cursor: 'pointer' }}
                  >
                    mute music
                  </Typography>
                  <Typography
                    level="body-sm"
                    onClick={() => setMusicVolume(1)}
                    sx={{ cursor: 'pointer' }}
                  >
                    music {Math.ceil((musicVolume ?? 0) * 100)}%
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Drawer>
        {/* </Grid> */}

        <Modal
          open={flowCompletedOpen}
          onClose={() => setFlowCompletedOpen(false)}
        >
          <ModalDialog>
            <ModalClose />

            <DialogTitle>Flow Completed</DialogTitle>
            <DialogContent sx={{ textAlign: 'center' }}>
              <Typography level="body-md">
                ✨ Congratulations! 🎉 You have completed the flow 🌟
              </Typography>
              <Typography level="body-md" sx={{ marginTop: '1rem' }}>
                🙏 Thank you for practicing with us 🧘‍♂️
              </Typography>
              <Typography level="body-md" sx={{ marginTop: '1rem' }}>
                How did you like it? Please rate the flow below:
              </Typography>

              <div style={{ whiteSpace: 'nowrap' }}>
                <Rating
                  rating={userRating ?? 0}
                  steps={5}
                  onRate={(rating) => {
                    setUserRating(rating)
                  }}
                />
              </div>
              <Typography level="body-md" sx={{ marginTop: '1rem' }}>
                💬 If you have time, we love to hear your feedback 🙏
              </Typography>
              <Textarea
                placeholder="Your feedback"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="plain"
                onClick={() => setFlowCompletedOpen(false)}
              >
                Close
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  if (currentUser && stats?.id) {
                    ServiceFactory.stats
                      .feedback(
                        currentUser,
                        flowId,
                        stats.id,
                        userRating,
                        feedback,
                      )
                      .then((stats) => {
                        setStats(stats)
                        setFlowCompletedOpen(false)
                      })
                  }
                  setFlowCompletedOpen(false)
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </ModalDialog>
        </Modal>
      </Grid>
    </>
  )
}

export { FlowPlayer, FlowLoader }
