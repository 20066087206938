import React from 'react'
import './rating.css'
const Rating = (params: {
  onRate: (rating: number) => void
  steps: number
  rating: number
  containerClass?: string
}) => {
  const { onRate, steps, rating, containerClass } = params
  return (
    <div className={containerClass ?? 'rating'}>
      {/*iterate over the number of steps and create sad to happy faces */}
      {[...Array(steps).keys()].map((i) => (
        <span
          key={i}
          onClick={() => onRate(i + 1)}
          className={`star ${i < rating ? 'highlighted' : ''}`}
        >
          ★
        </span>
      ))}
    </div>
  )
}

export default Rating
