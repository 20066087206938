import { Flow, FlowingPose, FlowingTransition } from '../types'

export enum FlowPlayerEvent {
  LOADING = 'loading',
  LOADED = 'loaded',
  PLAYING = 'playing',
  PAUSED = 'paused',
  COUNTDOWN = 'countdown',
  COMPLETED = 'completed',
  SEQUENCE_START = 'sequenceStart',
  TRANSITION_START = 'transitionStart',
  INTENTION_START = 'intentionStart',
  ELAPSED_TIME = 'elapsedTime',
}

class FlowPlayerService extends EventTarget {
  private transitionStart: number = 0
  private flow: Flow
  private breathLengthMS: number
  private isPaused: boolean = false
  private currentTransition = -1
  private currentTransitionTimeout: ReturnType<typeof setTimeout> | undefined
  private countdownTimeout: ReturnType<typeof setTimeout> | undefined
  private elapsedTimer: ReturnType<typeof setTimeout> | undefined
  private totalDuration: number = 0
  private elapsedDuration: number = 0

  // New variables to store remaining times on pause
  private storedTransitionRemaining: number = 0
  private storedCountdownRemaining: number = 0
  private currentTransitionDuration: number = 0
  private currentTransitionData: FlowingTransition | null = null

  constructor(params: { flow: Flow; breathLengthMS: number }) {
    super()
    const { flow, breathLengthMS } = params
    this.flow = flow
    this.breathLengthMS = breathLengthMS
    this.calculateTotalDuration()
  }

  private emit(event: FlowPlayerEvent, data?: any) {
    this.dispatchEvent(new CustomEvent(event, { detail: data }))
  }

  private calculateTransitionDuration(transition: FlowingTransition): number {
    const audioDuration = transition.audio?.duration ?? 0
    const nextPoseBreathsDuration =
      transition.to?.type === 'Pose'
        ? transition.to.holdFor > 0
          ? this.breathLengthMS * transition.to.holdFor
          : this.breathLengthMS / 2
        : 0
    const stayHereFor =
      audioDuration > nextPoseBreathsDuration
        ? audioDuration + 3000 // Add buffer
        : nextPoseBreathsDuration
    return stayHereFor
  }

  private calculateTotalDuration() {
    this.totalDuration = this.flow.transitions.reduce((acc, transition) => {
      return acc + this.calculateTransitionDuration(transition)
    }, 0)

    // Add initial pose duration
    const firstPose = this.flow.transitions[0]?.from as FlowingPose
    if (firstPose) {
      const firstPoseAudioDuration = firstPose.audioClip?.duration ?? 0
      const firstPoseBreathsDuration =
        firstPose.holdFor > 0
          ? this.breathLengthMS * firstPose.holdFor
          : this.breathLengthMS / 2
      const firstPoseTime =
        firstPoseAudioDuration > firstPoseBreathsDuration
          ? firstPoseAudioDuration + 3000
          : firstPoseBreathsDuration
      this.totalDuration += firstPoseTime
    }
  }

  private startElapsedTimer() {
    const tick = () => {
      if (this.isPaused) return
      this.elapsedDuration += 1000
      this.dispatchEvent(
        new CustomEvent<{ elapsed: number; total: number }>(
          FlowPlayerEvent.ELAPSED_TIME,
          {
            detail: {
              elapsed: this.elapsedDuration,
              total: this.totalDuration,
            },
          },
        ),
      )

      if (this.elapsedDuration < this.totalDuration) {
        this.elapsedTimer = setTimeout(tick, 1000)
      } else {
        this.dispatchEvent(new Event(FlowPlayerEvent.COMPLETED))
      }
    }

    this.elapsedTimer = setTimeout(tick, 1000)
  }

  private setCountdownTimer(transitionDuration: number) {
    if (this.countdownTimeout) clearTimeout(this.countdownTimeout)

    const countdownStart = Date.now()

    const countdown = () => {
      if (this.isPaused) return
      const timeLeft = transitionDuration - (Date.now() - countdownStart)
      this.emit(FlowPlayerEvent.COUNTDOWN, timeLeft)
      this.storedCountdownRemaining = timeLeft

      if (timeLeft > 0) {
        this.countdownTimeout = setTimeout(countdown, 1000)
      }
    }

    this.countdownTimeout = setTimeout(countdown, 1000)
  }

  private setTransitionTimer(
    transitionDuration: number,
    transition: FlowingTransition,
  ) {
    if (this.currentTransitionTimeout)
      clearTimeout(this.currentTransitionTimeout)

    this.currentTransitionDuration = transitionDuration
    this.currentTransitionData = transition
    this.transitionStart = Date.now()

    this.currentTransitionTimeout = setTimeout(() => {
      this.doTransition(transition)
    }, transitionDuration)
  }

  public pause(): void {
    this.isPaused = true

    // Calculate remaining transition time
    if (this.currentTransitionData && this.currentTransitionDuration) {
      const timeElapsedInTransition = Date.now() - this.transitionStart
      this.storedTransitionRemaining =
        this.currentTransitionDuration - timeElapsedInTransition
    }

    clearTimeout(this.currentTransitionTimeout)
    clearTimeout(this.countdownTimeout)
    clearTimeout(this.elapsedTimer)

    this.emit(FlowPlayerEvent.PAUSED, true)
  }

  public resume(): void {
    if (!this.isPaused) return
    this.isPaused = false
    this.emit(FlowPlayerEvent.PLAYING, true)

    // Resume transition timer if there was time left
    if (this.currentTransitionData && this.storedTransitionRemaining > 0) {
      this.transitionStart = Date.now()
      this.currentTransitionTimeout = setTimeout(() => {
        this.doTransition(this.currentTransitionData!)
      }, this.storedTransitionRemaining)

      // Restart countdown timer from storedCountdownRemaining
      if (this.storedCountdownRemaining > 0) {
        this.setCountdownTimer(this.storedCountdownRemaining)
      }
    }

    // Restart elapsed timer
    this.startElapsedTimer()
  }

  public end(): void {
    clearTimeout(this.currentTransitionTimeout)
    clearTimeout(this.countdownTimeout)
    clearTimeout(this.elapsedTimer)

    this.currentTransitionTimeout = undefined
    this.countdownTimeout = undefined
    this.elapsedTimer = undefined
    this.currentTransition = -1

    this.emit(FlowPlayerEvent.COMPLETED, true)
  }

  public restart(): void {
    this.end()
    this.emit(FlowPlayerEvent.PLAYING, false)
    this.start()
  }

  public start(): void {
    this.currentTransition = 0
    this.elapsedDuration = 0 // Reset elapsed time
    this.emit(FlowPlayerEvent.ELAPSED_TIME, {
      elapsed: this.elapsedDuration,
      total: this.totalDuration,
    }) // Emit initial elapsed time

    const initialTransition = this.flow.transitions[this.currentTransition]
    const audioDuration = initialTransition.from.audioClip?.duration ?? 0
    const breathsDuration =
      (initialTransition.from as FlowingPose).holdFor * this.breathLengthMS

    const intentionTime = Math.max(audioDuration, breathsDuration)

    this.doIntention(
      initialTransition.from as FlowingPose,
      initialTransition,
      intentionTime,
    )
    this.setTransitionTimer(intentionTime, initialTransition)

    this.startElapsedTimer()
    this.emit(FlowPlayerEvent.PLAYING, true)
  }

  public doIntention(
    pose: FlowingPose,
    transition: FlowingTransition,
    duration: number,
  ) {
    this.emit(FlowPlayerEvent.INTENTION_START, {
      pose,
      nextTransition: transition,
    })
    this.transitionStart = Date.now()
    this.setCountdownTimer(duration)
  }

  public doTransition(transition: FlowingTransition) {
    if (this.isPaused) return

    this.currentTransition++
    if (this.currentTransition >= this.flow.transitions.length) {
      this.end()
      return
    }

    const nextTransition = this.flow.transitions[this.currentTransition]
    const duration = this.calculateTransitionDuration(transition)
    this.transitionStart = Date.now()
    this.emit(FlowPlayerEvent.TRANSITION_START, { transition, nextTransition })
    this.setCountdownTimer(duration)
    this.setTransitionTimer(duration, nextTransition)
  }

  public nextTransition(): void {
    if (this.currentTransition + 1 >= this.flow.transitions.length) {
      this.end()
      return
    }
    this.doTransition(this.flow.transitions[this.currentTransition])
  }
}

export default FlowPlayerService
