import Analytics, { AnalyticsInstance } from 'analytics'
// @ts-ignore
import countlyPlugin from '@analytics/countly'

class AnalyticsService {
  public instance: AnalyticsInstance

  constructor(
    // protected readonly auth: AuthService,
    protected readonly config: {
      app_name: string
      app_key: string
      server_url: string
    },
  ) {
    this.instance = Analytics({
      app: config.app_name,
      plugins: [
        countlyPlugin({
          app_key: config.app_key,
          server_url: config.server_url,
          remote_config: true,
          require_consent: true,
        }),
      ],
    })
  }
}

export { AnalyticsService }
