import { AxiosService } from '../axios-service'
import { AuthedUser, FlowUserAnalytics } from '../types'

export class StatsService extends AxiosService {
  constructor(private readonly config: { baseUrl: string; prefix: string }) {
    super()
  }

  async startFlow(
    currentUser: AuthedUser,
    flowId: string,
  ): Promise<FlowUserAnalytics> {
    return this.put<FlowUserAnalytics>({
      url: `${this.config.baseUrl}/${this.config.prefix}/flow/${flowId}/start`,
      data: {},
      currentUser,
    })
  }

  async completeFlow(
    currentUser: AuthedUser,
    flowId: string,
    statsId: string,
    duration: number,
  ): Promise<FlowUserAnalytics> {
    return this.put<FlowUserAnalytics>({
      url: `${this.config.baseUrl}/${this.config.prefix}/${statsId}/flow/${flowId}/complete`,
      data: { duration },
      currentUser,
    })
  }

  async feedback(
    currentUser: AuthedUser,
    flowId: string,
    statsId: string,
    rating: number,
    feedback?: string,
  ): Promise<FlowUserAnalytics> {
    return this.put<FlowUserAnalytics>({
      url: `${this.config.baseUrl}/${this.config.prefix}/${statsId}/flow/${flowId}/rate`,
      data: { rating, feedback },
      currentUser,
    })
  }
}
