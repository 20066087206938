import './app.css'
import { Capacitor } from '@capacitor/core'
import { StatusBar } from '@capacitor/status-bar'
import { Auth0Provider } from '@auth0/auth0-react'
import { CssVarsProvider } from '@mui/joy/styles'
import config from './service/config'
import '@fontsource/inter'
import { CssBaseline } from '@mui/joy'
import ReactGA from 'react-ga4'
import { ReactNode } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'

interface Props {
  children?: ReactNode
}
function App({ children }: Props) {
  if (
    Capacitor.getPlatform() === 'android' &&
    Capacitor.isPluginAvailable('StatusBar')
  ) {
    // Tint statusbar color
    StatusBar.setOverlaysWebView({ overlay: true })
    StatusBar.hide()
  }

  ReactGA.initialize('G-DX8V9VNR95')

  // const fontSize = 14 // px
  // // Tell Material-UI what's the font-size on the html element.
  // // 16px is the default font-size used by browsers.
  // const htmlFontSize = 16
  // const coef = fontSize / 14

  const theme = createTheme({
    palette: {
      mode: 'dark',
    },
    // typography: {
    //   pxToRem: (size) => `${(size / htmlFontSize) * coef}rem`,
    // },
  })

  // const { mode, setMode } = useColorScheme()
  // const [mounted, setMounted] = useState(false)

  return (
    <Auth0Provider
      domain={config.auth0.domain}
      clientId={config.auth0.clientId}
      useRefreshTokens={true}
      useRefreshTokensFallback={false}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: config.auth0.redirectUri.login,
        response_type: 'code',
      }}
    >
      <CssVarsProvider>
        <CssBaseline />
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </CssVarsProvider>
    </Auth0Provider>
  )
}

export default App
