import config from './config'
// import { AudioService } from './audio'
import { AuthService } from './auth'
import { FlowService } from './flow'
import { AnalyticsService } from './analytics'
import { StatsService } from './stats'
// import { ChakraService } from './chakra'
// import { ClipsService } from './clips'
// import { DispatchService } from './dispatcher'
// import { FlowService } from './flow'
// import { InitService } from './init'
// import { PoseService } from './pose'
// import { SequenceService } from './sequence'
// import { TransitionService } from './transitions'
// import { OpenAIChatService } from './openai'
// import { WSService } from './websocket'

export class ServiceFactory {
  public static services: { [key: string]: any } = {}

  static getService<T>(name: string, init: () => T): T {
    if (!(name in ServiceFactory.services)) {
      ServiceFactory.services[name] = init()
    }
    return ServiceFactory.services[name]
  }

  //   public static pose: PoseService = ServiceFactory.getService(
  //     'pose',
  //     () => new PoseService({ baseUrl: config.baseUrl, prefix: 'pose' }),
  //   )

  //   public static audio: AudioService = ServiceFactory.getService(
  //     'audio',
  //     () => new AudioService({ baseUrl: config.baseUrl, prefix: 'audio' }),
  //   )

  //   public static sequence: SequenceService = ServiceFactory.getService(
  //     'sequence',
  //     () => new SequenceService({ baseUrl: config.baseUrl, prefix: 'sequence' }),
  //   )

  //   public static transition: TransitionService = ServiceFactory.getService(
  //     'transition',
  //     () =>
  //       new TransitionService({ baseUrl: config.baseUrl, prefix: 'transition' }),
  //   )

  //   public static clips: ClipsService = ServiceFactory.getService(
  //     'clips',
  //     () => new ClipsService({ baseUrl: config.baseUrl, prefix: 'clips' }),
  //   )

  public static flow: FlowService = ServiceFactory.getService(
    'flow',
    () => new FlowService({ baseUrl: config.baseUrl, prefix: 'flow' }),
  )

  public static auth: AuthService = ServiceFactory.getService(
    'auth',
    () => new AuthService({ baseUrl: config.baseUrl, prefix: 'auth' }),
  )

  public static stats: StatsService = ServiceFactory.getService(
    'stats',
    () => new StatsService({ baseUrl: config.baseUrl, prefix: 'stats' }),
  )

  public static get analytics(): AnalyticsService {
    return ServiceFactory.getService(
      'analytics',
      () =>
        new AnalyticsService({
          app_name: 'ntntn',
          app_key: '32afa5c00e6cfb47930cc0e8089f8be743526fe2',
          server_url: 'https://ntntn-43599656b5686.flex.countly.com/',
        }),
    )
  }
  //   public static chakra: ChakraService = ServiceFactory.getService(
  //     'chakra',
  //     () => new ChakraService({ baseUrl: config.baseUrl, prefix: 'chakra' }),
  //   )

  //   public static init: InitService = ServiceFactory.getService(
  //     'init',
  //     () => new InitService({ baseUrl: config.baseUrl, prefix: '' }),
  //   )

  //   public static openai: OpenAIChatService = ServiceFactory.getService(
  //     'openai',
  //     () => new OpenAIChatService({ baseUrl: config.baseUrl, prefix: 'chat' }),
  //   )

  //   public static websocket: WSService = ServiceFactory.getService(
  //     'websocket',
  //     () => new WSService({ websocketUrl: config.WebSocketUrl }),
  //   )

  //   public static dispatcher(dispatch: Dispatch): DispatchService {
  //     return DispatchService.instance(dispatch)
  //   }
}
