import { Link } from '@mui/joy'
import React, { useState } from 'react'

const ShowMore = (params: { text: string; maxLength: number }) => {
  const { maxLength, text } = params
  const [showMore, setShowMore] = useState(false)

  // ensure this ends on the last word
  const trim = () => {
    if (!showMore) {
      /* // gather words until we reach the max length */
      const words = text.split(' ')
      while (words.join(' ').length > maxLength) {
        words.pop()
      }

      return text.length > maxLength ? (
        <>
          {`${words.join(' ')}... `}
          <Link
            variant="plain"
            onClick={(event) => {
              event.stopPropagation()
              setShowMore(!showMore)
            }}
          >
            {' '}
            more
          </Link>
        </>
      ) : (
        text
      )
    } else {
      return (
        <>
          {`${text} `}
          <Link
            variant="plain"
            onClick={(event) => {
              event.stopPropagation()
              setShowMore(!showMore)
            }}
          >
            {' '}
            less
          </Link>
        </>
      )
    }
  }
  return <p style={{ textAlign: 'justify' }}>{text && trim()}</p>
}

export default ShowMore
